import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'

function Internship() {
    return (
        <div>
            <hr />
            <Typography
                variant="h3"
                textAlign="center"
                fontWeight='bold'
                my='2%'
            >
                Internship
            </Typography>

            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Box sx={{ width: '70%', '@media screen and (max-width: 1250px)': { width: '90%' } }}>
                    <Typography textAlign='center' mb='1%' >
                        LueurTech provides various internship Skills to provide hands on experience to individuals before starting their career. Internship helps in providing practical knowledge of our subject. LueurTech provide internships in many tech stacks through various interactive batches, project based activities and much more to get train for a job.
                        In order to provide you with all the necessary skills, this period also contains various job interview skills which will help you refine your personality.
                    </Typography>

                    <Typography textAlign='center' mb='1%' >
                        <b> LueurTech Internship Programs suite </b> offers a wide range of internship courses aimed at technical and non-technical concepts made with the aim of creating capable, creative and tech-savvy professionals who combine theoretical know-how with practical expertise to solve complex situations by finding foolproof solutions to the problems.
                    </Typography>

                    <Typography textAlign='center' mb='1%' >
                        In LueurTech Software solutions Learn what you missed in the college. Take the first step of your journey to success. Join our India's largest Internship + Industrial Training program and learn from our industry experts having experience in working at Top tier Companies.
                    </Typography>

                    <Box my='5%'>

                        <Grid>
                            <Grid container spacing='5%' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                <Grid item sx={{ width: '35%', '@media screen and (max-width: 1250px)': { width: '100%' } }}>
                                    <Grid container textAlign='right' spacing='5%' >
                                        <Grid item>
                                            <Grid display='flex' boxShadow='1px 1px 5px 1px lightgrey' borderRadius='20px' padding='5%' alignItems='center'>
                                                <Stack mr='5%'>
                                                    <Typography variant='h6' fontWeight='bold' >Cutting Edge Technology</Typography>
                                                    <Typography>We work on the tech stacks which are in trend and are future.</Typography>
                                                </Stack>

                                                <Grid>
                                                    <img src='./assets/edge-tech.png' height='100px' width='100px' />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid display='flex' boxShadow='1px 1px 5px 1px lightgrey' borderRadius='20px' padding='5%' alignItems='center'>
                                                <Stack mr='5%'>
                                                    <Typography variant='h6' fontWeight='bold' >Industrial interactions</Typography>
                                                    <Typography>We help you interact and get involved in the industry based learning.</Typography>
                                                </Stack>

                                                <Grid>
                                                    <img src='./assets/interaction.png' height='100px' width='100px' />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid display='flex' boxShadow='1px 1px 5px 1px lightgrey' borderRadius='20px' padding='5%' alignItems='center'>
                                                <Stack mr='5%'>
                                                    <Typography variant='h6' fontWeight='bold' >Experienced leadership</Typography>
                                                    <Typography>Get an opportunity to work with highly experienced leaders.</Typography>
                                                </Stack>

                                                <Grid>
                                                    <img src='./assets/leadership.png' height='100px' width='100px' />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item sx={{ width: '30%', '@media screen and (max-width: 1250px)': { width: '50%' } }}>
                                    <img src='./assets/teamwork.png' height='100%' width='100%' />
                                </Grid>

                                <Grid item sx={{ width: '35%', '@media screen and (max-width: 1250px)': { width: '100%' } }}>
                                    <Grid container spacing='5%' sx={{ '@media screen and (max-width: 1250px)': { justifyContent: 'right' } }}>
                                        <Grid item>
                                            <Grid display='flex' boxShadow='1px 1px 5px 1px lightgrey' borderRadius='20px' padding='5%' alignItems='center' >
                                                <Grid>
                                                    <img src='./assets/teamwork.png' height='100px' width='100px' />
                                                </Grid>

                                                <Stack ml='5%'>
                                                    <Typography variant='h6' fontWeight='bold' >Team work</Typography>
                                                    <Typography>We give you an environment where you can upskill your team's working strategies.</Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid display='flex' boxShadow='1px 1px 5px 1px lightgrey' borderRadius='20px' padding='5%' alignItems='center'>
                                                <Grid>
                                                    <img src='./assets/ethics.png' height='100px' width='100px' />
                                                </Grid>

                                                <Stack ml='5%'>
                                                    <Typography variant='h6' fontWeight='bold' >Work ethics</Typography>
                                                    <Typography>We provide a healthy working style and an opportunity to explore IT culture.</Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid display='flex' boxShadow='1px 1px 5px 1px lightgrey' borderRadius='20px' padding='5%' alignItems='center'>
                                                <Grid>
                                                    <img src='./assets/live-project.png' height='100px' width='100px' />
                                                </Grid>

                                                <Stack ml='5%'>
                                                    <Typography variant='h6' fontWeight='bold' >Work on live projects</Typography>
                                                    <Typography>We offer interactive learning where you work on running projects.</Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} mt='6%'>
                                <Typography variant='h4' mb='2%'>Technologies that we offer internship in:</Typography>

                                <Grid container justifyContent='center' >
                                    <Grid item width='25%' sx={{ '@media screen and (max-width: 1250px)': { width: '30%' } }}>
                                        <Grid container display='flex' boxShadow='1px 1px 5px 1px lightgrey' borderRadius='20px' padding='10%' justifyContent='center' textAlign='center' height='400px'>
                                            <Grid>
                                                <img src='./assets/full-stack.png' height='120px' width='120px' />
                                            </Grid>

                                            <Stack spacing={1} mt='2%'>
                                                <Typography variant='h6' fontWeight='bold' >Full stack web development</Typography>
                                                <Typography>An opportunity to showcase the skills of on demand technical concepts in web design & development and learning while developing particular webpages.</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    <Grid item width='25%' mx='10%' sx={{ '@media screen and (max-width: 1250px)': { mx: '2%', width: '30%' } }} >
                                        <Grid container display='flex' boxShadow='1px 1px 5px 1px lightgrey' borderRadius='20px' padding='10%' justifyContent='center' textAlign='center' height='400px'>
                                            <Grid>
                                                <img src='./assets/java.png' height='120px' width='120px' />
                                            </Grid>

                                            <Stack spacing={1} mt='2%'>
                                                <Typography variant='h6' fontWeight='bold' >Java development</Typography>
                                                <Typography>Work on the frameworks of Java with development of a full fledged MVC application where you'll have an opportunity to explore the modern way of application development with the framework.</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    <Grid item width='25%' sx={{ '@media screen and (max-width: 1250px)': { width: '30%' } }}>
                                        <Grid container display='flex' boxShadow='1px 1px 5px 1px lightgrey' borderRadius='20px' padding='10%' justifyContent='center' textAlign='center' height='400px'>
                                            <Grid>
                                                <img src='./assets/machine-learning.png' height='120px' width='120px' />
                                            </Grid>

                                            <Stack spacing={1} mt='2%'>
                                                <Typography variant='h6' fontWeight='bold' >Artificial intelligence & Machine learning</Typography>
                                                <Typography>A great future ahead with AI needs advanced technology to have focus on, python programming solves many such use-cases and has vast experiences ahead.</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
            </div>
        </div>
    )
}

export default Internship