import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'

function Training() {
    return (
        <div id='training' style={{ marginBottom: '2%' }}>
            <hr />
            <Typography
                variant="h3"
                textAlign="center"
                fontWeight='bold'
                my='2%'
            >
                Training
            </Typography>

            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Box sx={{ width: '70%', '@media screen and (max-width: 1250px)': { width: '90%' } }}>
                    <Typography textAlign='center' mb='1%' >
                        LueurTech Software solutions is a place where you Uplevel your experienced tech talent with advanced training on the latest complex cutting-edge tech topics taught by the world's leading experts.
                        Our tech content is consistent in quality as a result of our in-house tech strategists, instructional designers, and production artists partnered with diverse thought leaders and the world’s leading subject matter experts.
                    </Typography>

                    <Typography textAlign='center' mb='1%' >
                        We provide deep coverage of platforms, applications, programming languages, and methodologies that tech professionals need, from today's in-demand technical skills to tomorrow's future trends.
                        The Technology library offers current and aspiring technology professionals deep coverage of the platforms, applications, and programming languages that are driving economic growth around the globe. Courses cover a wide range of in-demand skills— full-stack web development, java, AIML, and more.
                    </Typography>

                    <Typography textAlign='center' mb='1%' >
                        Our team has years of expertise in providing high end Certified IT skills, soft skills training, behavioral skills along with global staffing and recruiting industry. We are industry experts and build and train industry enthusiasts the skills necessary for climbing the corporate ladder. Our training solutions are highly cost effective and tailor-made for each customer needs.
                    </Typography>

                    <Typography textAlign='center' mb='1%' >
                        Technical training teaches the skills required to design, develop, install, maintain, support, or operate a certain technology, application, product, or service.
                        With new software applications and cutting-edge technologies developing on a daily basis across industries, organizations must constantly upskill and reskill their personnel.
                    </Typography>

                    <Box mt='4%'>
                        <Grid container spacing='5%' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >

                            <Grid item sx={{ width: '20%' }}>
                                <Grid>
                                    <img src='./assets/why.png' width='150px' height='150px' />
                                </Grid>
                            </Grid>

                            <Grid item sx={{ width: '80%' }}>
                                <Grid container borderRadius='20px' boxShadow='1px 1px 10px 1px lightgrey' sx={{ padding: '2%', justifyContent: 'center' }} >
                                    <Stack>
                                        <Typography fontWeight='bold' variant='h6' textAlign='center'>Why is training required?</Typography>
                                        <ul>
                                            <li>
                                                <Typography>
                                                    <b>Industrial expertise: </b>
                                                    To have a knowledge of the tools and services used in the industry for better outcomes and also gain desired skills.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography>
                                                    <b>Tech stack exposure: </b>
                                                    Get an understanding of the technology used in the corporate world for the best products to deliver and have consistently well product.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography>
                                                    <b>Hands on experience: </b>
                                                    To have worked on the real products and to know the way things are built in the industry.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography>
                                                    <b>Guidance: </b> To have an opportunity to get trained or guided by the experts and experienced people in the industry and understand the requirements of the world.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography>
                                                    <b>Technical know-how: </b>
                                                    To have the overall flow of the operations being performed in all aspects not only the development tech stacks but also the deployment and maintenance.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </Stack>
                                </Grid>
                            </Grid>


                            <Grid item sx={{ width: '80%' }}>
                                <Grid container borderRadius='20px' boxShadow='1px 1px 10px 1px lightgrey' sx={{ padding: '2%', justifyContent: 'center' }} >
                                    <Stack>
                                        <Typography fontWeight='bold' variant='h6' textAlign='center'>What we provide?</Typography>
                                        <ul>
                                            <li>
                                                <Typography>
                                                    <b>Industrial collaboration: </b>
                                                    we offer interactive sessions with the industry individuals and also projects to work on which are currently in the market.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography>
                                                    <b>Skill based training: </b>
                                                    Our training focuses mainly on the hands on experience while working on the projects given which enhances the technical skills.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography>
                                                    <b>Highly qualified and experienced trainers: </b>
                                                    our training faculty team have years of experience in the field and are also the industrial experts who had also contributed to the industry with their skills.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography>
                                                    <b>Dedicated workshop: </b>
                                                    we provide workshop which consists of guest lecturers and also a specific tech stack exposures.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography>
                                                    <b>Live project works: </b>
                                                    We have various projects that are already in the market, you'll be working on these projects to be a valuable asset in order to add new functionality and improvement.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <Grid item sx={{ width: '20%' }}>
                                <Grid>
                                    <img src='./assets/what.png' width='150px' height='150px' />
                                </Grid>
                            </Grid>


                            <Grid item sx={{ width: '20%' }}>
                                <Grid>
                                    <img src='./assets/programming.png' width='150px' height='150px' />
                                </Grid>
                            </Grid>

                            <Grid item sx={{ width: '80%' }}>
                                <Grid borderRadius='20px' boxShadow='1px 1px 10px 1px lightgrey' sx={{ padding: '2%', display: 'flex', justifyContent: 'center' }} >
                                    <Stack>
                                        <Typography fontWeight='bold' variant='h6' textAlign='center'>Technologies that we offer training in:</Typography>
                                        <ul>
                                            <li>
                                                <Typography>Java full stack</Typography>
                                            </li>

                                            <li>
                                                <Typography>Full stack web development</Typography>
                                            </li>

                                            <li>
                                                <Typography>Machine learning with python </Typography>
                                            </li>
                                        </ul>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>

        </div>
    )
}

export default Training