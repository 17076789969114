import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Intern from './Jobs/InternWeb'

var selectedCareer 

function Career({ career }) {

    const [salaryText, setSalaryText] = useState("")

    useEffect(() => {
        if (career.type === "Intern") {
            setSalaryText("Stipend")
        } else {
            setSalaryText("Salary")
        }
    }, [])
    
    const applyButton = {
        borderRadius: '15px',
        backgroundColor: 'teal',
        color: 'white'
    }

    const careerCard = {
        margin: '0 14% 2% 14%',
        borderRadius: '10px',
        boxShadow: '1px 1px 5px 3px rgb(199, 199, 199)'
    }

    return (
            <Card style={careerCard}>
                <CardContent>
                    <Stack spacing={1.5} ml={3} >
                        <Typography variant='h4' fontWeight='bold' >{career.title}</Typography>

                        <Stack direction='row' spacing={4} >
                            <Typography>Experience: {career.experience}</Typography>
                            <Typography>{salaryText}: {career.salary}</Typography>
                        </Stack>

                        <Stack direction='row' spacing={4} >
                            <Typography>Job Type: {career.type}</Typography>
                            <Typography>Location: {career.location}</Typography>
                        </Stack>
                                
                        <Stack direction='row' spacing={4} alignItems='center' >
                            <Button variant='contained' href={career.url} style={applyButton} >Read and Apply</Button>
                            <Typography>Last Date to Apply: {career.lastDate}</Typography>
                        </Stack>
                    </Stack>
                </CardContent>

                
            </Card>
    )
}

export default Career