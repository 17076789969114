import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  styled,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";

const FormBox = styled(Box)({
  background: "rgb(231, 243, 239)",
  borderRadius: "20px",
  "&:hover": {
    boxShadow: "2px 2px 25px 7px rgb(199, 199, 199)",
    transition: "0.5s",
  },
});

const SubmitButton = styled(Button)({
  background: "red",
  color: "white",
  "&:hover": {
    background: "red",
    color: "white",
    boxShadow: "2px 2px 10px 2px red",
  },
});

function ContactUsForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [gender, setGender] = useState("");
  const [message, setMessage] = useState("");
  const [source, setSource] = useState("");

  const [emailURL, setEmailURL] = useState("");
  useEffect(() => emailjs.init("D6_xcSz9iCRW7DFTf"), []);

  const [open, setOpen] = useState(false);
  const [dialogText, setDialogText] = useState("");

  const validEmail = new RegExp(
    "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
  );
  const validContact = new RegExp("^[1-9][0-9]{9}$");

  const handleSubmit = () => {
    if (name === "") {
      setOpen(true);
      setDialogText("Name should not be empty");
    } else if (!validEmail.test(email)) {
      setOpen(true);
      setDialogText("Email is invalid");
    } else if (!validContact.test(contact)) {
      setOpen(true);
      setDialogText("Contact Number is invalid");
    } else if (gender === "") {
      setOpen(true);
      setDialogText("Please select gender from the given list");
    } else if (message === "") {
      setOpen(true);
      setDialogText("Message should not be empty");
    } else if (source === "") {
      setOpen(true);
      setDialogText(
        "Please select the source from where you got to know about us"
      );
    } else {

      const serviceId = "service_jcykvmp"
      const templateId = "template_2pazlhs"
      try {
        emailjs.send(serviceId, templateId, {
          name: name,
          email: email,
          contact: contact,
          gender: gender,
          message: message,
          source: source
        });
        alert("Thanks for reaching out to us.");
      } catch (error) {
        console.log(error);
      }

      setName("");
      setEmail("");
      setContact("");
      setGender("");
      setMessage("");
      setSource("");
    }
  };

  return (
    <FormBox mb="5%">
      <Grid container>
        <Grid item xs={6}>
          <Box height="50%" my="30%">
            <img src="/assets/contactForm.jpg" width="100%" height="100%" />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={4} mx={10} mt={10} mb={5}>
            <TextField
              variant="outlined"
              label="Name"
              style={{ backgroundColor: "white" }}
              value={name}
              onChange={(event) => setName(event.target.value)}
              helperText={false && "HELLO"}
            />

            <TextField
              variant="outlined"
              label="Email"
              style={{ backgroundColor: "white" }}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />

            <TextField
              variant="outlined"
              type="number"
              label="Contact No."
              style={{ backgroundColor: "white" }}
              value={contact}
              onChange={(event) => setContact(event.target.value)}
            />

            <FormControl style={{ backgroundColor: "white" }}>
              <InputLabel>Gender</InputLabel>
              <Select
                label="Gender"
                value={gender}
                onChange={(event) => setGender(event.target.value)}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Prefer Not to say">Prefer Not to say</MenuItem>
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              label="Your Message"
              multiline
              maxRows={5}
              value={message}
              style={{ backgroundColor: "white" }}
              onChange={(event) => setMessage(event.target.value)}
            />

            <FormControl style={{ backgroundColor: "white" }}>
              <InputLabel>How did you hear about us?</InputLabel>
              <Select
                label="How did you hear about us?"
                value={source}
                onChange={(event) => setSource(event.target.value)}
              >
                <MenuItem value="Web Search">Web Search</MenuItem>
                <MenuItem value="Word of mouth reference">
                  Word of mouth reference
                </MenuItem>
                <MenuItem value="Social media post">Social media post</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>
            </FormControl>

            <SubmitButton
              style={{ marginLeft: "40%", marginRight: "40%" }}
              onClick={() => handleSubmit()}
            >
              Submit
            </SubmitButton>

            <Dialog open={open}>
              <DialogTitle>Something went wrong!</DialogTitle>

              <DialogContent>
                <DialogContentText>{dialogText}</DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button
                  style={{ backgroundColor: "lightblue", color: "black" }}
                  variant="outlined"
                  onClick={() => setOpen(false)}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Stack>
        </Grid>
      </Grid>
    </FormBox>
  );
}

export default ContactUsForm;
