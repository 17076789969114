import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CareersPage from "../Careers/CareersPage";
import InternWeb from "../Careers/Jobs/InternWeb";
import InternPython from "../Careers/Jobs/InternPython";
import PersonalAssistant from "../Careers/Jobs/PersonalAssistant";
import WebTrainer from "../Careers/Jobs/WebTrainer";
import JavaTrainer from "../Careers/Jobs/JavaTrainer";
import PythonTrainer from "../Careers/Jobs/PythonTrainer"
import PrivacyPoliciesPage from "../Privacy_Policies/PrivacyPoliciesPage";
import HomePage from "../Home/HomePage";
import ContactUsPage from "../Contact_Us/ContactUsPage";
import OurServicesPage from "../Our_Services/OurServicesPage";
import AboutUsPage from "../About_Us/AboutUsPage";

function Body() {

    return (
        <div>
            <Router>
                <Routes>

                    {/* Home */}
                    <Route path='/' element={<HomePage />} exact />

                    {/* Careers */}
                    <Route path='/careers' element={<CareersPage />} exact />
                    <Route path='/careers/intern-web' element={<InternWeb path={'/careers/intern-web'} />} exact />
                    <Route path='/careers/intern-python' element={<InternPython path={'/careers/intern-python'} />} exact />
                    <Route path='/careers/personal-assistant' element={<PersonalAssistant path={'/careers/personal-assistant'} />} exact />
                    <Route path='/careers/web-trainer' element={<WebTrainer path={'/careers/web-trainer'} />} exact />
                    <Route path='/careers/java-trainer' element={<JavaTrainer path={'/careers/java-trainer'} />} exact />
                    <Route path='/careers/python-trainer' element={<PythonTrainer path={'/careers/python-trainer'} />} exact />

                    {/* Services */}
                    <Route path='/services' element={<OurServicesPage />} exact />

                    {/* Contact Us */}
                    <Route path='/contact-us' element={<ContactUsPage />} exact />

                    {/* About Us */}
                    <Route path='/about-us' element={<AboutUsPage />} exact />

                    {/* Privacy Policies */}
                    <Route path='/privacy-policies' element={<PrivacyPoliciesPage />} ></Route>

                </Routes>
            </Router>
        </div>
    )

}

export default Body