import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";
import React from "react";

const ReadMoreButton = styled(Button)({
  background: "white",
  color: "black",
  textTransform: "capitalize",
  border: "2px solid teal",
  borderRadius: "30px",
  padding: "1.5%",
  marginTop: "4%",
  "&:hover": {
    color: "white",
    background: "teal",
    boxShadow: "2px 5px 10px grey",
    transition: "0.5s",
  },
});

function OurServices() {

  const responsive = {
    '@media screen and (max-width: 1250px)': {
      width: '100%',
      mt: '3%'
    }
  }

  return (
    <div style={{ marginBottom: "4%" }}>
      <Typography
        variant="h4"
        fontWeight="bold"
        textAlign="center"
        fontSize={36}
        mt="4%"
        mb="2%"
      >
        OUR SERVICES
      </Typography>

      <Box display="flex" justifyContent="center" mx="17%">
        <Grid container>
          <Grid item width='33%' textAlign="center" sx={responsive} >
            <Stack
              spacing={3}
              mx="5%"
              padding="10%"
              paddingBottom="5%"
              alignItems="center"
              sx={{ '@media screen and (max-width: 1250px)': { padding: '5%' } }}
            >
              <img
                src="./assets/development_home.png"
                width="250px"
                height="250px"
              />
              <Typography fontWeight="bold" variant="h6">
                Software Development
              </Typography>
              <Typography style={{ minHeight: '200px' }}>
                We design, develop, and deploy highly customizable commercial web & software solutions meeting your unique business requirements, optimizing your business processes. We specialize in developing customized software adhering to narrowly defined requirements.
              </Typography>
              <Button
                sx={{
                  padding: "2% 5% 2% 5%",
                  color: "teal",
                  fontWeight: "bold",
                  fontSize: "16px",
                  "&:hover": { backgroundColor: "#94FFFF", transition: "0.5s" },
                }}
                href="./services"
              >
                Read More
              </Button>
            </Stack>
          </Grid>

          <Grid item width='34%' textAlign="center" sx={responsive}>
            <Stack
              spacing={3}
              mx="5%"
              padding="10%"
              paddingBottom="5%"
              alignItems="center"
              sx={{ '@media screen and (max-width: 1250px)': { padding: '5%' } }}
            >
              <img
                src="./assets/internship_home.png"
                width="250px"
                height="250px"
              />
              <Typography fontWeight="bold" variant="h6">
                Internship
              </Typography>
              <Typography style={{ minHeight: '200px' }}>
                We provides various internship Skills to provide hands on experience to individuals before starting their career. Internship helps in providing practical knowledge of the industry. LueurTech provide internships in many tech stacks through various interactive batches, project based activities and much more.
              </Typography>
              <Button
                sx={{
                  padding: "2% 5% 2% 5%",
                  color: "teal",
                  fontWeight: "bold",
                  fontSize: "16px",
                  "&:hover": { backgroundColor: "#94FFFF", transition: "0.5s" },
                  bottom: '0'
                }}
                href="./services"
              >
                Read More
              </Button>
            </Stack>
          </Grid>

          <Grid item width='33%' textAlign="center" sx={responsive} >
            <Stack
              spacing={3}
              mx="5%"
              padding="10%"
              paddingBottom="5%"
              alignItems="center"
              sx={{ '@media screen and (max-width: 1250px)': { padding: '5%' } }}
            >
              <img
                src="./assets/training_home.png"
                width="300px"
                height="250px"
              />
              <Typography fontWeight="bold" variant="h6">
                Training
              </Typography>
              <Typography style={{ minHeight: '200px' }}>
                LueurTech Software solutions is a place where you uplevel your experienced talent with advanced training on latest cutting-edge tech topics taught by leading experts. We provide deep coverage of platforms and technologies from today's in-demand skills to tomorrow's future trends.
              </Typography>
              <Button
                sx={{
                  padding: "2% 5% 2% 5%",
                  color: "teal",
                  fontWeight: "bold",
                  fontSize: "16px",
                  "&:hover": { backgroundColor: "#94FFFF", transition: "0.5s" }
                }}
                href="./services"
              >
                Read More
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default OurServices;
